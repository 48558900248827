import React from "react";
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import "./common/style/style.css";
import RouteSetting from "./RoutesSetting";
import {Provider} from 'react-redux';
import store from './redux/store';

if(window.location.hostname === "catmo-sandbox.statussquad.dev"){
    Sentry.init({
        dsn: "https://dfa7b0f92d834830affcbf46a9aed6de@appmonitor.e2infosystems.in/12",
        integrations: [new Sentry.BrowserTracing()],
      
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    })
}

const root = ReactDOM.createRoot(document.getElementById("root")); // Create a root
root.render(
    <Provider store={store}>
        <RouteSetting />
    </Provider>,
     );
