import React, {useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { IoArrowBackOutline } from "react-icons/io5"

import Table from "../../../common/component/Table";
import BreadCrumb from "../../../common/component/BreadCrumb";
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import ManageMustering from '../ManageMustering';
import NoCurrentEvent from './NoCurrentEvent';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import { setGroups } from '../../../redux/action/groupAction';
import { validatePermission } from "../../../common/functions/permissionFunctions";
import { setCurrentMusteringEventUserList,setAdvisorDetails,setUserType,setUser,setMusteringLocation,setSnackData, setLoading, setActiveMusteringList} from '../../../redux/action/userAction';

import "../../Style/Application.css";
import "./Mustering.css"
import MusteringList from './MusteringList';
import {getRoleName} from "../../../common/functions/commonFunctions"

function Mustering(){
	var dispatch = useDispatch()
	
	const users = useSelector(state => state.userReducer.users)
	const groups = useSelector(state => state.groupReducer.groups)
	const userTypes = useSelector(state => state.userReducer.userTypes)
	const adviserDetails = useSelector(state => state.userReducer.advisorDetails)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const musteringLoaction = useSelector(state=>state.userReducer.musteringLocation)
	const EventusersList = useSelector(state => state.userReducer.currentMusteringEventUserList)
	const [role,setRole] = useState("");
	const statusFilters = {
		"checkedIn":"1",
		"notCheckedIn":"2",
		"absent":"3"
	}
	const [status, setStatus] = useState("");
	const [grade,setGrade]=useState('');

	const [hasAdvisor, setHasAdvisor] = useState(true)
	const [openManageMusteringModal,setOpenManageMusteringModal] = useState(false)
		const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/application/mustering",display:"Application",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/application/mustering",display:"Mustering",type:"react"},
	
		];
	const [viewFlag,setViewFlag] = useState(2)
	const [MusteringModalProps,setMusteringModalProps]=useState([])
	const deploymentConfiguration = useSelector(state => state.deployementReducer.deployementConfiguration)
	useEffect(() => {
		if(viewFlag === 3){
			dispatch(setLoading(true))
			fetchMusteringLocation()
		}	
		dispatch(setLoading(true))
		fetchCurrentMusteringEvent()
	}, [viewFlag])
	
	useEffect(() => {
		if(deploymentConfiguration.Deployment){
			setHasAdvisor(deploymentConfiguration.Deployment.hasAdvisor)
		}
	}, [deploymentConfiguration])
	const [newArray, setNewArray] = useState([]);
	let tableHeaders = [
		{ id: 'checkedIn', label: 'Checked In', type: 'check_icon', width: 80, justifyContent : "center", responsive: true,dataTestId:"checkedIn"},
		{ id: 'needMedicalEmergency', label: 'Medical', type: 'check_icon', width: 64, dataTestId:"medical",justifyContent : "center"},
		{ id: 'isAbsent', label: 'Absent', type: 'check_icon', width: 64, dataTestId:"absent",justifyContent : "center"},
		{ id: 'fullName', label: 'Full Name', width: 120, responsive: true, sortable: true ,dataTestId:"fullName"},
		{ id: 'profilePicture', label: 'Picture', type: 'image', width: 60, responsive: true ,dataTestId:"profilePicture"},
		{ id: 'role', label: 'Role', path: '#', width: 120 ,dataTestId:"role"},
		{ id: 'advisorName', label: 'Advisor', width: 80,dataTestId:"advisorName" },
		{ id: 'locationName', label: 'Location', width: 100 ,dataTestId:"locationName"},
		{ id: 'lastUpdatedDateTime', label: 'Last Updated', width: 120 , sortable: true,dataTestId:"lastUpdatedDateTime"},
		{ id: 'notes', label: 'Notes',justifyContent : "center",type:"notes",width: 60 ,dataTestId:"notes"},
	]
	
	if (!hasAdvisor) {
		tableHeaders = tableHeaders.filter(function (obj) {
			return obj.id !== "advisorName";
		});
	}

	useEffect(() => {}, [tableHeaders])
	useEffect(() => {
		let data = []
		EventusersList.users?.map((user) => {
			user.format = "DD MMM, YYYY hh:mm A"
			if (user.lastUpdated){
				let dateObj = moment(user.lastUpdated+".0000000Z", "YYYY-MM-DD HH:mm:ss.0000000Z");
				user.lastUpdatedDateTime = moment.utc(dateObj).format("DD MMM, YYYY hh:mm A")
			}
			data.push({
				alertId: user.alertId,
				checkedIn: user.checkedIn,
				customLocation: user.customLocation,
				firstName: user.firstName,
				format: user.format,
				groupIds: user.groupIds,
				isSafe: user.isSafe,
				lastName: user.lastName,
				lastUpdatedDateTime: user.lastUpdatedDateTime,
				loactionName: user.loactionName,
				locationId: user.locationId,
				newMusteringLocation: user.newMusteringLocation,
				notes: user.notes,
				profileMediaId: user.profileMediaId,
				role: user.role,
				roleId: user.roleId,
				userId: user.userId,
				isAbsent:user.isAbsent,
				advisorId:user.advisorId,
				needMedicalEmergency:user.needMedicalEmergency
			})
			setNewArray(data)
		})
	}, [EventusersList, viewFlag]);
	const fetchCurrentMusteringEvent=async()=>{
		let url = URL.currentMusteringEventwithFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.currentMusteringevent
		}
		let response = await API.getAPI(url)
		// fetchMusteringLocation()
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				if(Object.keys(response.result.result).length <= 0 || response.result.result == null ){
					return setViewFlag(1)
				}
				dispatch(setActiveMusteringList(response.result.result))
			}else{
				setViewFlag(1)
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			setViewFlag(1)
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	
	const fetchAdvisor = async () => 
	{
		let response = await API.getAPI(URL.advisorByFacility + "?facilityId=" + localStorage.getItem("facilityId"))
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setAdvisorDetails(userListTypes))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const fetchMusteringLocation=async()=>{
		let url = URL.musteringLocationWithFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.musteringLocation
		}
		let response = await API.getAPI(url) 	
		fetchUserType()
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				dispatch(setMusteringLocation(response.result.result))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const fetchUserType=async()=>{
		let response = await API.getAPI(URL.rolesByFacility + "?facilityId=" + localStorage.getItem("facilityId"))
		fetchUser()
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setUserType(userListTypes))
				
			} else {
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
	
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		
		if (deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.hasAdvisor) {
			fetchAdvisor()
		} else {
			dispatch(setLoading(false))
		}

		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let groupList = response.result.result
				for (let i = 0; i < groupList.length; i++) {
					groupList[i].userCount = groupList[i].userIds.length
					if (groupList[i].isEventGroup) {
						groupList.splice(i,1)
						i--
					}
				}
				dispatch(setGroups(groupList))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const fetchSpecificMusteringDetails = async() =>{
        let url = URL.specificMusteringEventUsers +"?alertId=" +  EventusersList.id;
		// if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
		// 	url = URL.currentMusteringevent
		// }
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
                setViewFlag(3)
                dispatch(setCurrentMusteringEventUserList(response.result.result))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
    }
	const fetchUser = async () => {
		let url = URL.usersByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.users
		}
		let response = await API.getAPI(url)
		fetchGroup()		
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let userList = response.result.result	
				dispatch(setUser(userList));
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
		
	}
	const formatTableData = (userListTypes) => {
		let userList = newArray
		let tempData = []
		userList && userList.forEach((user) => {
			let tempEventUser = user
			tempEventUser["fullName"] = user.firstName + " " + user.lastName
			users && users.map((tempUser)=>{
				if(tempUser.id == tempEventUser.userId){
					tempEventUser["roleId"] = tempUser.roleIds
					tempEventUser["groupIds"] = tempUser.groupIds
					tempEventUser["advisorId"] = tempUser.advisorId
					if (user.customLocation !== 0) {
						tempEventUser["locationName"] = user.newMusteringLocation
					}
				   if(user.customLocation !== 0){
					   tempEventUser["locationName"] = user.newMusteringLocation
				   }
				   	adviserDetails && adviserDetails.map((advisor)=>{
						if(tempUser.advisorId==advisor.id){
							tempEventUser["advisorName"] = advisor.firstName + " "+advisor.lastName
						}
						
					})
				}
				
			})
			musteringLoaction && musteringLoaction.map((location)=>{
				if(location.id == user.locationId && user.customLocation === 0){
					tempEventUser["locationName"]=(location.musteringLocation)
				}
			})
			
			let tempObj = {}
			tempObj = tempEventUser
			tempObj["alertId"]=EventusersList.id
			if (userList.lastUpdated && moment(userList.lastUpdated)) {
				let dateObj = moment(userList.lastUpdated)
				tempObj["lastUpdated"] = moment.utc(dateObj).format("DD MMM, hh:mm A ")				
			}
			tempObj["role"] = user.roleId ? getRoleName(userListTypes, user.roleId) : '-'
			tempData.push(tempObj)
		})
		return tempData
	
	}
	const editMusteringEvent =(data)=>{
		setOpenManageMusteringModal(true)
		data.saraAlertId = EventusersList.saraAlertId
		setMusteringModalProps(data)
	}
	let userData = formatTableData(userTypes)
	const [search,setSearch] = useState('')
	const [location , setLocation] = useState('')

	var tempCheckInCount = 0 ;
	var tempUnassignedCount = 0;
	var tempAbsentCount = 0;
	if(userData.length>0){
		for(let i=0;i<userData.length;i++){
			if(userData[i].checkedIn === 1){
				tempCheckInCount++;
			}

			if(userData[i].advisorId === 0){
				tempUnassignedCount++;
			}
			if(userData[i].isAbsent === 1){
				tempAbsentCount++;
			}
		}
}

	const filter = (values) =>{
		if (!role && !search && !grade && !location && !status) {
			return values
		}

		let tempValues = [];
		if(role && role.length > 0){
			values.map((value)=>{
				if(value.roleId && value.roleId.includes(parseInt(role))){
					tempValues.push(value)
				}	
			})
		}else{
			tempValues = values
		}
		let updatedGroupValues = [];
		if(grade && grade.length > 0){
			tempValues.map((value)=>{
				(value.groupIds || []).map((grpId,index)=>{
					if(grpId == grade){
						updatedGroupValues.push(value)
					}
				})
			})
		}else{
			updatedGroupValues = tempValues
		}
		let updatedLocationValue = []
		if(location && location.length > 0){
			updatedGroupValues.map((value)=>{
				if(value.locationId == parseInt(location)){
					updatedLocationValue.push(value)
				}
			})
		}else{
			updatedLocationValue = updatedGroupValues
		}
		let updatedValues = []
		if(search){
			updatedLocationValue.map((value)=>{

				if(
					(value.fullName || "").toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
					(value.locationName || "").toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
				){
					updatedValues.push(value)
				}
			})
		}else{
			updatedValues = updatedLocationValue
		}

		let updatedStatusValues = []
		if (status && status.length > 0) {
			updatedValues.map((value) => {
				if (
					value.checkedIn === 1 && status === statusFilters["checkedIn"] ||
					value.checkedIn !== 1 && status === statusFilters["notCheckedIn"] ||
					value.isAbsent === 1 && status === statusFilters["absent"]
				) {
					updatedStatusValues.push(value)
				}
			})
		} else {
			updatedStatusValues = updatedValues
		}
		return updatedStatusValues
	}
	if (validatePermission(userPermissions, "UPDATE_MUSTERING")) {
		tableHeaders.push({ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 70, responsive: true })
	}
	return(
		<div className='mustering-container ' data-testid="mustering-container" id="wrapper">
			<BreadCrumb  crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
			{
				viewFlag === 2 ? <MusteringList setViewFlag = {setViewFlag}/>
		
				: viewFlag === 3 ?
			
				<div>
					<div className="application-options-container">
						<div className="display-flex space-between active-mustering-details-ctr">
							<div className='active-mustering-title'>
								<span className="mustering-title" data-testid="mustering-title">Mustering - {EventusersList.title} - {moment(EventusersList.alertCreatedTime).format("DD MMMM YYYY")}</span>
							</div>
							{EventusersList.alertTriggeredBy && <span className='active-mustering-alarm-triggered-by' data-testid ="active-mustering-triggered-by-ctr">Alarm triggered by: <span className='active-mustering-alarm-triggered-by-name'>{EventusersList.alertTriggeredBy}</span> </span>}
							<div className="mustering-search-section"> 
								<div className="display-flex mustering-search-ctr">
									<div className="search-label" data-testid="search-label">Search for a User</div>
									<div className="searching">
										<input type="text" data-testid="search-input" value={search} className="margin-left-10" placeholder="Search" onChange={(e)=>{setSearch(e.target.value)}}></input>
									</div>
								</div>
							</div>
							<div className="current-result-title" data-testid="current-result-title">
								Current Result
							</div>
							<div className='status-bar'>
								<div className="display-flex current-result current-result-1">
									<div data-testid="current-result">Current Results</div>
								</div>
								<div className="display-flex current-result current-result-2">
									<div data-testid="check-in-text">Checked In <span className="count-bold" data-testid="checkin-count">({tempCheckInCount})</span></div>
								</div>
								{hasAdvisor && <div className="display-flex current-result current-result-2">
									<div data-testid="unassigned-text">Unassigned <span className="count-bold" data-testid="unassigned-count">({tempUnassignedCount})</span></div>
								</div>}
								<div className="display-flex current-result">
									<div data-testid="absent-text">Absent <span className="count-bold"data-testid="absent-count">({tempAbsentCount})</span></div>
								</div>
							</div>	
							
						</div>
						<span className="back-to-history back-to-mustering">
							<button onClick={()=>{setViewFlag(2)}} className="back-to-mustering-btn" data-testid="back-to-mustering-page" id="back-to-mustering-page"> <IoArrowBackOutline />Back to Mustering</button>
						</span>
						<div className="not-responsive">	
							<div  className="display-flex  col-80 margin-5 ">
								<div className='col-22'>
									<span data-testid="role-text">Role</span>	
								</div>
								<div className='col-22  margin-left-2' >
									<span data-testid="group-name-text">Group Name</span>	
								</div>
								<div className='col-22 margin-left-2'>
									<span data-testid="status-text">Status</span>	
								</div>
								<div className='col-22 margin-left-2'>
									<span data-testid="location-text">Location</span>	
								</div>
							</div>
						</div>
						<div  className="display-flex col-80 margin-top-2 ">
							<span className="muster-responsive" data-testid="user-type">User Type</span>
							<select className='user-page-select' value={role} onChange={(e)=>{setRole(e.target.value)}} data-testid="user-type-select">
								<option value=''>All</option>
								{userTypes.map((type, index)=>
									<option value={type.id} key={index} data-testid="user-type-option">{type.roleName}</option>
								)}
							</select>		
							<span className="muster-responsive" data-testid="group-name-responsive">Group Name</span>		
							<select className='user-page-select' value={grade} onChange={(e)=>setGrade(e.target.value)} data-testid="group-select">
								<option value=''>All</option>
								{groups.map((group, index) =>
									<option value={group.id}  key={index} data-testid="group-option">{group.groupName}</option>
								)}
							</select>
					
							<span className="muster-responsive" data-testid="status-responsive">Status</span>  
							<select className='user-page-select' value={status} onChange={(e) => setStatus(e.target.value)} data-testid="status-select">
								<option value='' data-testid="status-option-all">All</option>
								<option value={statusFilters["checkedIn"]} data-testid="status-option-0">Checked In</option>
								<option value={statusFilters["notCheckedIn"]}  data-testid="status-option-1">Not Checked In</option>
								<option value={statusFilters["absent"]}  data-testid="status-option-4">Absent</option>
							</select>
							
							<span className="muster-responsive" data-testid="location-responsive">Location</span>
							<select className='user-page-select' value={location} onChange={(e)=>setLocation(e.target.value)} data-testid="location-select">
								<option value=''>All</option>
								{musteringLoaction.map((location, index) =>
									<option value={location.id}  key={index} data-testid="location-option">{location.musteringLocation}</option>
								)}
							</select>
							<div style={{ display: "flex" }} className="filter-reset-container">
								<button className="clear-btns " onClick={()=>{setSearch('');setRole('');setGrade("");setLocation("");setStatus("")}} data-testid="reset-btn">Reset</button>
								<button className="refresh-btn" onClick={()=>{setSearch('');setRole('');setGrade("");setLocation("");setStatus("");
							       dispatch(setLoading(true))
								   fetchSpecificMusteringDetails();	
							 }
							}
								data-testid="refresh-btn">Refresh</button>
							</div>
						</div>
					</div>

					<div className="application-table-container ">
						<Table
							headers={tableHeaders}
							data={filter(userData)}
							defaultOrderBy={"firstName"}
							defaultSortOrder={"asc"}
							editAction={(data) => { editMusteringEvent(data) }}
							includeMedia = {true}
						/>
					</div>
					{openManageMusteringModal&&<ManageMustering onCancel={(e)=>{
						fetchSpecificMusteringDetails();
						setOpenManageMusteringModal(false)}} musteringProps={MusteringModalProps}/>}
				</div> 
				:
				<NoCurrentEvent /> 
			} 
		</div>
	)
}
export default Mustering;
