import { useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";

import User from '../../assets/images/user.png';
import Groups from '../../assets/images/group.png';
import System from '../../assets/images/system.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import { validatePermission } from "../../common/functions/permissionFunctions"
import '../Style/style.css';

function Dashboard() {
	const navigate = useNavigate()
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const [crumbs, setCrumbs] = useState(['Dashboard', 'user'])
	const selected = crumb => {
		setCrumbs(crumb)
	}
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
	];
	return (
		<div className='dashboard'>
			<div className="margin-left-12">
				<BreadCrumb dataTestid="dashboard-breadcrum" crumbs={BreadCrumbArr} selected={selected} ></BreadCrumb>
			</div>			
			<div className="row" data-testid="dashboard-container">
				{validatePermission(userPermissions, "ADD_USERS") ? 
					<div className="card" data-testid="add-user-dashboard-card">
						<h1 className="addUser_title" id="add-user-title" data-testid="dashboard-add-user-title">Add User</h1> <br />
						<img src={User} className='user_icon' data-testid="dashboard-add-user-icon" title="add-user-icon" alt="Add User Icon" />
						<br />
						<p >Quick Access to Users on your CATIE Mobile Application</p><br />
						<p>You can also select User and Groups from the navigation to the left to manage Users</p>
						<br />
						<button className='large-button' data-testid='dashboard-add-user-btn-id' onClick={() => navigate("/home/users/add-user")}>Add User</button>
					</div>
				:null}

				{validatePermission(userPermissions, "ADD_GROUPS") ? 
					<div className="card" data-testid="add-group-dashboard-card">
						<h1 className="addUser_title" data-testid="dashboard-add-group-title">Add Group</h1>
						<br />  <img src={Groups} data-testid="dashboard-add-group-icon" alt="Add Group Icon" className='group_icon' title="add-group-icon" /><br />
						<p >Quick Access to Groups on your CATIE Mobile Application</p><br />
						<p>You can also select User and Groups from the navigation to the left to manage Groups
						</p> <br />
						<button className='large-button' data-testid='dashboard-add-group-btn-id' onClick={() => navigate("/home/groups/add")}>Add Group</button>
					</div>
				:null}

				{validatePermission(userPermissions, "VIEW_SERVER_CONFIG") ? 
					<div className="card" data-testid="add-system-dashboard-card">
						<h1 className="addSystem_title" data-testid="dashboard-add-system-title">System</h1>
						<br /> <img src={System} className='system_icon' alt="Add System Icon" data-testid="dashboard-add-system-icon" title="add-system-icon" /><br />
						<p >Quick Access to System setting on your CATIE Mobile Application</p><br />
						<p>Configure,manage and edit settings on CATIE Mobile Application.</p><br />
						<button className='large-button' data-testid='dashboard-add-system-btn-id' onClick={() => navigate('/home/system/server-configuration')} >Manage System</button>
					</div>
				:null}
			</div>
			{!validatePermission(userPermissions, "ADD_USERS") && !validatePermission(userPermissions, "ADD_GROUPS") && !validatePermission(userPermissions, "VIEW_SERVER_CONFIG") ?
				<div className="no-menu-dashboard">No features Available at the moment</div>
				: null}
		</div>
	);
}

export default Dashboard;
