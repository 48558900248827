import { useRef, useEffect, useState } from 'react';
import '../style/SelectBox.css';
import dropdown_arrow from '../../assets/svg/dropdown_arrow.svg';
import {FaRegEdit} from 'react-icons/fa'
import {RiDeleteBinLine} from 'react-icons/ri';
import AddNewIcon from '../../../src/HomeLayout/Component/Application/AddNewIcon';
import Confirmation from "../../common/component/Confirmation";
import { setSnackData, setAlertIcon,setLoading} from '../../redux/action/userAction';
import {useDispatch} from 'react-redux';
import * as API from '../../common/api/index';
import URL from "../../common/api/constantURL";
import DownArrow from "../../assets/svg/toggle-icon.svg";
import PropTypes from 'prop-types';
import {searchFilesWithParents} from '../../HomeLayout/Component/Application/DocumentsAndImages/CategoryFileList';

function SelectBox(props) {
	const [isEditIconPopUp,setIsEditIconPopUp] = useState(false);
	const [editIconData, setEditIconData] = useState({});
	const [deleteConfirtmationPopUp,setDeleteConfirtmationPopUp] = useState(false);
	const [confirmationRemoveIconAllocation, setConfirmationRemoveIconAllocation] = useState(false)
	const [deleteData ,setDeleteData]= useState({
		id:"",
		filename:"",
		name:""
	});
	const dispatch = useDispatch()
	const ref = useRef();
	const [categories, setCategories] = useState(props.type === "Category" ? props.option : null);
	const [search, setSearch] = useState('');
	useEffect(() => {
		
		const handleClickOutSide = (e) => {
			if (props.trigger && ref.current && !ref.current.contains(e.target)) {
				props.setTrigger(false)
				setSearch('');
			}
		};
		const timer = setTimeout(() => {
            document.addEventListener("click", handleClickOutSide);
        }, 0);
    
        return () => {
            clearTimeout(timer); 
            document.removeEventListener("click", handleClickOutSide);
        };
	}, [props])

	const setAllCollapsedTrue = (categories) => {
		return categories.map((category) => ({
			...category,
			isOpen: false,
			categories: category.categories ? setAllCollapsedTrue(category.categories) : [],
		}));
	};

	useEffect(() => {
		if (props.type === "Category") setCategories(setAllCollapsedTrue(categories));
	}, [props.trigger]);

	const showEditAction = (data) =>{
		setEditIconData(data)
		setIsEditIconPopUp(true)
	}

	const fetchIcon = async () => {
        let url = URL.iconByFacility + "?facilityId=" + localStorage.getItem("facilityId");
        if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
            url = URL.icon
        }
        let response = await API.getAPI(url)
        if (response.fetchStatus === "success") {
            dispatch(setAlertIcon(response.result))
        } else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
        }
    }
	
	const deleteIcon = async()=>{
		dispatch(setLoading(true))
		let response = await API.deleteAPI(URL.icon, deleteData)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				fetchIcon();
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
			}else if (response.result.status === "failure") {
				setConfirmationRemoveIconAllocation(true)
				setDeleteData({facilityId: deleteData.facilityId,id:deleteData.id,filename: deleteData.filename,name:deleteData.name,isDefault: deleteData.isDefault, deleteAllocation:"true"})
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to delete. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}


	const toggleCollapse = (id) => {
	  const toggle = (items) =>
		items.map((item) => {
		  if (item.id === id) {
			return { ...item, isOpen: !item.isOpen };
		  }
		  if (item.categories) {
			item.categories = toggle(item.categories);
		  }
		  return item;
		});
  
	  setCategories(toggle(categories));
	};

	const renderCategories = (items, depth = 0) => (
		<ul style={{ paddingLeft:`${ 10}px` }}>
			{items.map((item) => (
				<li key={item.id} style={{ listStyleType: "none" }}>
					<div
						style={{
							cursor: "pointer",
							padding: "5px",
							marginLeft: `${0}px`,
						}}
						className = 'dropdown-item'
						data-testid="category-item"
					> 
							<button data-testid="toggle-btn" onClick={() => {
								toggleCollapse(item.id);
							}}>
								<img 
									src={DownArrow}
									alt="downArrow"
									className={item.isOpen ? "toggle-button " : "toggle-button rotate"}
									style={item.categories?.length > 0 ? {visibility:"visible", marginBottom:"-4px"} : {visibility:"hidden"}}
								/> 
							</button>
						<button onClick={() => {
							props.setTrigger(false);
							props.selectedItem(item);
							props.depth(depth);
							props.setValid(false);
							setSearch('');
						}}
						style={{width:"90%"}}
						data-testid="category-name"
						>
						{item.name}
						</button>
					</div>
					{item.isOpen && item.categories.length > 0 && renderCategories(item.categories, depth + 1)}
				</li>
			))}
		</ul>
	  );

	  useEffect(() => {
		  if (props.type === "Category") setCategories(searchFilesWithParents(props.option, search, true));
	  },[search]);

	return (
		<>	
		<div className="dropdown category-dropDown" data-testid={props.dataTestid}>
			<div className={!props.valid ? `dropdown-btn ${props.type === "Category" ? "category-dropdown" : ""} validDropdown` : `dropdown-btn  ${props.type === "Category" ? "category-dropdown" : ""} inValidDropdown`}
				onClick={() => props.setTrigger(!props.trigger)} data-testid="custom-select" id="custom-select">
				<span>
					{props.selectItem ?
						props.selectItem.hexValue ?
							<div className="selected-item">
								{/* <img src={props.selectItem.hexValue}  className="circle" style={{backgroundColor:props.selectItem.hexValue}} /> */}
								<div style={{ backgroundColor: props.selectItem.hexValue }} className="selected-color-box"></div>
								<span title={props.selectItem.name} className="inner-padding" data-testid="selected-color" id="selected-color">
									{props.selectItem.name}
								</span>
							</div>
							: props.selectItem.url ?
							<div className="selected-item">
										{props.selectItem.url && <img src={props.selectItem.url} className="select-box-icon" alt="Icon" />}
										<span title={props.selectItem.name} className="inner-padding" data-testid="selected-icon" id="selected-icon">
											{props.selectItem.name}
										</span>
									</div>
									: <div className="selected-item">
								<span title={props.selectItem.name} className="inner-padding" data-testid="selected-icon" id="selected-icon" style={props.type === 'Category' ? {paddingLeft: '3px'} : {}}>
									{props.selectItem.name}
								</span>
							</div>
						:
						<span className={props.valid ? 'invalidSelectItem' : null}>{props.type === 'Category' ?"No Category Selected":"Select"}</span>
					}
				</span>
				<span className="dropdown-arrow">
					<img src={dropdown_arrow} className={!props.trigger ? "drop-down-arrow" : "drop-down-arrow-rotation"} alt="" />
				</span>
			</div>
			{props.trigger ?
					<div className="dropdown-content" ref={ref} data-testid="options">
						{props.type === 'Category' ? 
							<input 
							placeholder="Search"
							data-testid="search"
							value={search} onChange={(e) => setSearch(e.target.value)}
							></input>
						: null}
						{props.type === 'Icon'
							?
							<span className="dropdown-item" style={{justifyContent:"center",fontSize:"13px"}}onClick={(e) => {
								props.setTrigger(false)
								props.selectedItem(null)
							}}>
								Select
							</span>
							: props.type === 'Category' ? <span className="dropdown-item" style={{justifyContent:"flex-start",fontSize:"13px", paddingLeft: '3px'}}onClick={(e) => {
								props.setTrigger(false)
								props.selectedItem(null)
							}}>
								No Category Selected
							</span> : null
							
						}
					{props.type !== 'Category' && props.option ? props.option.map(((d, index) =>
						<div className="dropdown-item" 
						data-testid="select" id="custom-select-drop-down-item"
						>
							<div className="options-list" key={index}
								onClick={(e) => {
									props.selectedItem(d)
									props.setTrigger(false)
									props.setValid(false)
								}}
								>
							{props.type === 'Icon' ?
								<img src={d.url} className="select-box-icon" alt="Icon"/>
								:
								props.type === 'Color' ?
									<span className="circle" style={{ backgroundColor: d.hexValue }}></span>
									:
									null
							}
							<span title={d.name} className="select-box-value" data-testid="select-box-value" id="select-box-value" >
								{d.name}
							</span>
							</div>
							<div>
							{props.type === 'Icon' ?
							<div className='manage-icons-ctr'>
							<span>
							<FaRegEdit className='data-update-delete-icon' id="edit-action-icon" data-testid="edit-icon" onClick={()=>{showEditAction(d)}} />
							</span>
							<span >
							<RiDeleteBinLine className='data-update-delete-icon' data-testid="delete-icon" id="delete-icon" onClick={()=>{setDeleteData({facilityId: d.facilityId,id:d.id,filename: d.fileName,name:d.name,isDefault: d.isDefault});setDeleteConfirtmationPopUp(true)}}/>
							</span>
							</div>
							: ""}
							</div>
					</div>
					)) : null}
					{props.type === 'Category' && <div data-testid="category-tree" className="dropdown-tree">{renderCategories(categories)}</div>}
 			</div>
				:
				null
			}
		</div>
			{confirmationRemoveIconAllocation &&
				<Confirmation className="modal-indexed"  buttonValue="Proceed" deleteMessage={"Icon is currently used in existing alarm buttons, are you sure you want to delete?"} successFunc={() => {  setConfirmationRemoveIconAllocation(false);setDeleteConfirtmationPopUp(false);deleteIcon()}} cancelFunc={() => setConfirmationRemoveIconAllocation(false)} dataTestid="delete-allocation" />
			}
			{deleteConfirtmationPopUp ?
				<Confirmation className="modal-indexed" deleteMessage={"Are you sure you want to delete " + deleteData.name + "?"} successFunc={() => {setDeleteConfirtmationPopUp(false);deleteIcon()}} cancelFunc={() => setDeleteConfirtmationPopUp(false)} />
				: ""}
			{isEditIconPopUp ? <AddNewIcon trigger={isEditIconPopUp} 
			data = {editIconData}
			setTrigger={() => {
			fetchIcon()
			setIsEditIconPopUp(false)
		}} /> : null}
		</>

	)
}

SelectBox.propTypes = {
	selectItem: PropTypes.object,
	option: PropTypes.array,
    type: PropTypes.string,
    dataTestid: PropTypes.string,
    selectedItem: PropTypes.func,
	setTrigger: PropTypes.func,
	setValid: PropTypes.func,
	trigger: PropTypes.bool,
    valid: PropTypes.bool,
	depth: PropTypes.number
};

export default SelectBox;
