import React from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router";
import LandingLayout from "./LandingLayout/LandingLayout";
import HomeLayout from "./HomeLayout/HomeLayout";
import PageNotFound from "./common/component/PageNotFound";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate replace to="/user/login" />,
    },
    {
      path: "/user/*",
      element: <LandingLayout />,
    },
    {
      path: "/home/*",
      element: <HomeLayout />,
    },
    {
      path: "/:PageName",
      element: <PageNotFound />,
    },
  ]);
  
function RouteSetting() {
    return <RouterProvider router={router} />;
}

export default RouteSetting;
