import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams} from "react-router"
import Table from '../../../common/component/Table';
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BreadCrumb from '../../../common/component/BreadCrumb';
import Confirmation from "../../../common/component/Confirmation"
import AddGroups from './AddGroups';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import { setUser, setSnackData, setLoading, setUserType} from '../../../redux/action/userAction';
import { setAllGroups, setGroups } from '../../../redux/action/groupAction';
import { validatePermission } from "../../../common/functions/permissionFunctions";
import '../../Style/style.css';
import ViewGroup from './ViewGroup';
import BatchImprtPopUp from '../../../common/component/BatchImprtPopUp';
import moment from 'moment-timezone';
import { setDevices} from '../../../redux/action/deviceAction';
import fileUpload from "../../../assets/svg/file-upload-solid.svg";
import sampleGroupXlsx from "../../../assets/sampleSheets/GroupSample.xlsx";
import sampleGroupCsv from "../../../assets/sampleSheets/GroupSample.csv";

function Groups(){
	const groups = useSelector(state => state.groupReducer.allGroups)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const dispatch = useDispatch();
	const params = useParams()
	const [openModal, setOpenModal] = useState( params.operation === "add" ? true : false);
	const [confirmation, setConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] = useState(false);
	const [viewFlag,setViewFlag] = useState(1)
	const [search,setSearch]=useState('');
	const [role,setRole]=useState('');
	const [propGroupName,setPropGroupName] = useState('')
	const [modalProps, setModalProps] = useState({});
	const [batchImportpopUp,setBatchimportPopUp] = useState(false)
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/users",display:"Users and Groups",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/groups",display:"Groups",type:"react"},
	];
	let tableHeaders = [
		{ id: 'groupName', label: 'Group Name', width: 100, responsive: true, sortable: true, dataTestid: "group-name-col"},
		{ id: 'userCount', label: "Total Count", width: 100, responsive: true, dataTestid: "total-users-col"},
		{ id: 'createdBy', label: "Created By", width:100, dataTestid: "created-col"},
		{ id: 'createdOn', label: 'Created On', width: 100 },
	]
	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		fetchUser();
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let groupList = response.result.result
				for (let i = 0; i < groupList.length; i++) {
					groupList[i].userCount = (groupList[i].userIds.length)+(groupList[i].deviceIds.length);
					groupList[i].createdOn = moment(groupList[i].createdAt).format("LL")
				}
				dispatch(setAllGroups(groupList))
				dispatch(setGroups(groupList))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const batchImportHandler=async(fileData)=>{
		var form_data = new FormData();
		dispatch(setLoading(true))
		if (fileData.current.files.length > 0) {
			form_data.append("dataFile",fileData.current.files[0]);
		}
		
		form_data.append("facilityId", localStorage.getItem("facilityId"));
		form_data.append("administratorId", localStorage.getItem("loginUserId"));

		let response = await API.postWithFileAPI(URL.batchImportGroup, form_data);
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
				setBatchimportPopUp(false)
				fetchGroup()
			}else{
				fileData.current.value = ""
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			fileData.current.value = ""
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to upload. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const fetchUser = async () => {
		let url = URL.usersByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.users
		}
		let response = await API.getAPI(url)
		fetchUserType()
		if(response.fetchStatus === "success"){
			let userList = response.result.result
			if(response.result.status === "success"){
				dispatch(setUser(userList));
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const fetchDevice = async () => {
		let url = URL.devices + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.devices
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			let devicesList = response.result.result
			if(response.result.status === "success"){
				dispatch(setDevices(devicesList));
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}

	}

	const fetchUserType = async () => {
		let response = await API.getAPI(URL.rolesByFacility + "?facilityId=" + localStorage.getItem("facilityId"))
		fetchDevice()	
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userListTypes = response.result.result
				dispatch(setUserType(userListTypes))
			} else {
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	
	}
	const showEditAction = (data) =>{
		setModalProps(data)
		setOpenModal(true)
	}
	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationData(data)
	}
	const deleteGroup = async () => {
		dispatch(setLoading(true))	
		let data = {
			"id": confirmationData.id,
			"facilityId": localStorage.getItem("facilityId")
		}
		let response = await API.deleteAPI(URL.groups, data)
		dispatch(setLoading(false))	
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				setConfirmation(false)
				fetchGroup();
				dispatch(setSnackData(snackData))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server Down.Failed to delete.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	
	const filter = (values) =>{
		let tempValues = [];
		tempValues = values
		let updatedValues = []
		if(search && search.length > 0){
			tempValues.map((value)=>{
				if(
					value.groupName.toLowerCase().includes(search.toLowerCase())
				){
					updatedValues.push(value)
				}
			})
		}else{
			updatedValues = tempValues
		}
		
		if (!role && ! search){
			tempValues = values;
		}
		return updatedValues
	}
	useEffect(() => {
		dispatch(setLoading(true))
		fetchGroup();
	}, []); 
	if (validatePermission(userPermissions, "EDIT_GROUPS") || validatePermission(userPermissions, "DELETE_GROUPS")) {
		tableHeaders.push({ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 80, responsive: true })
	}

	const formatTableData = (groups) => {
		let groupList = groups
		let tempData = []
		groupList && groupList.map((group) => {
			let tempObj = {}
			tempObj = group
			if (group.isEventGroup) {
				tempObj["disableEditForRow"] = true
				tempObj["disableDeleteForRow"] = true
			}
			tempData.push(tempObj)
		})
		return tempData
	}

	let groupData = formatTableData(groups);

	return (
		<div className="group-page" id="wrapper">  
			{viewFlag === 1?	
				<div data-testid="group-managemnt-ctr-testid">
					<BreadCrumb  crumbs={BreadCrumbArr} dataTestid="groups-breadcrum" ></BreadCrumb>
					<div className="top-content">
						<div className="list-page-title-div">
							<p data-testid="group-management-title-id" className='list-page-title'> Group Management Options</p>
						</div>
						{validatePermission(userPermissions, "ADD_GROUPS") ? 
							<div className="button_parent_div">
								<button
									className="common-button"
									data-testid="single-group-btn-id"
									onClick={() => { setOpenModal(true) }}
								>
									Create New Group
								</button>
								<button className="import-button" data-testid="batch-import-btn-id" onClick={() => { setBatchimportPopUp(true) }}>
									<span>Batch Create Group</span>
									<img src={fileUpload} alt="file-upload" className='import_icon'/>
								</button>
							</div>
						: ""}
						<div className="columns">
							<div className="col col-12 bold" >
								<p data-testid="user-selection-title-id" className="group-selction-title">Group Selection Option</p> 
							</div>
							<div className="col col-12">
								<p data-testid="user-selection-id" className='user-selection-id'>Select a Group for Detailed View</p>
							</div>
						</div>

						<div className="columns group-filters-ctr">
							<div className="col col-12 groups-select-filter-container">
								<select className='groups-select-container' value={role} onChange={
									(e)=>{
										setViewFlag(2)
										setRole(e.target.value)
										setPropGroupName(e.target.innerText)
									}} 
									data-testid="role-id"
									id="group-search-role-id"
								>
									<option value=''>All</option>
									{groups.map((group, index) =>
										!group.isEventGroup ?
										<option key={index} id={"group-select-role-"+index} value={group.id}>{group.groupName}</option> : ""
									)}
								</select>
							</div>
							<div className="col col-33 margin-left-1-percent search">
								<input type="text" value={search} className="group-search" placeholder="Search" id="group-search-box" data-testid="group-search-box" onChange={(e)=>setSearch(e.target.value)}></input>
							</div>
							<div style={{ display: "flex" }} className="reclear group-reset-btn">
								<div data-testid="users-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch('')}} >Reset</div>
							</div>
						</div>           
					</div>  
					<div className="bottom-content "> 
						<div className="col col-100">
							<Table
								headers={tableHeaders}
								data={filter(groupData)}
								defaultOrderBy={"groupName"}
								defaultSortOrder={"asc"}
								editAction={validatePermission(userPermissions, "EDIT_GROUPS") ?(data)=> showEditAction(data):null}
								deleteAction={validatePermission(userPermissions, "DELETE_GROUPS") ?(data) => showDeleteAction(data):null}
								tableTitle={"Group Name Details - Total Groups "}
								disableDeleteForRow={true}
							/>
						</div>  
					</div> 
					{openModal ? 
						<AddGroups 
							data={modalProps} 
							onCancel={(e) => {
								fetchGroup()
								setOpenModal(false)
								setModalProps({})
							}} 
						/>
					:null}
					{confirmation ?
						<Confirmation successFunc={() => deleteGroup()} cancelFunc={() => setConfirmation(false)} deleteMessage={"Are you sure you want to delete group "+ confirmationData.groupName + "?"} />
					:""}
					{
						batchImportpopUp && <BatchImprtPopUp batchImportTitle={"Groups"} onClose={()=>{setBatchimportPopUp(false)}} download={"GroupSample"} csvDownloadSampleLink={sampleGroupCsv} xlsxDownloadSample={sampleGroupXlsx} fileData={(fileData)=>{batchImportHandler(fileData)}}/>
					}
				</div>
			:
				<ViewGroup 
					role = {role} 
					propGroupName = {propGroupName}
					onClose={()=>{
						fetchGroup()
						setViewFlag(1)        
						setRole('')}
					}
				/>
			}
		</div>
	)
}
export default Groups;
