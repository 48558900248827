import variables from "../variables/globals";
const path = {
    login: variables.domain+"/login",
    getUser: variables.domain+"/users",
    addUser: variables.domain+"/users",
    users: variables.domain + "/users",
    usersByFacility: variables.domain + "/usersByFacility",
    refreshToken: variables.domain+"/refreshToken",
    forgotPassword: variables.domain+"/forgetPassword",
    resetPassword: variables.domain+"/resetPassword",
    changePassword: variables.domain+"/changePassword",

    batchImportUsers:variables.domain+"/users/bulkUpload",
    batchImportGroup:variables.domain+"/groups/bulkUpload",
    batchImportDevice:variables.domain+"/devices/bulkUpload",

    roles: variables.domain+"/roles",
    rolesByFacility: variables.domain+"/roles/rolesByFacility",
    rolesByFacilityPermission: variables.domain + "/roles/fetchByFacility",

    advisorByFacility: variables.domain+"/users/advisorsByFacility",

    profileDetailId: variables.domain+"/users/fetchByUserId",

    groups: variables.domain+"/groups",
    groupsByFacility: variables.domain+"/groups/fetchByFacility",
    groupNamesByFacility: variables.domain+"/groups/fetchGroupNamesByFacility",

    integrations: variables.domain + "/manageIntegrations",

    accounts: variables.domain + "/accounts",
    color: variables.domain + "/colors",
    iconByFacility: variables.domain + "/icons/fetchAllByFacility",
    icon: variables.domain + "/icons",

    facility: variables.domain + "/facility",
    serverConfiguration: variables.domain + "/facility/getConfig",
    updateServerConfig: variables.domain + "/facility/updateConfig",
    getFacilitybyAccount: variables.domain + "/facility/fetchAllByAccountUser",
    getFacilityById: variables.domain+"/facility/fetchById",

    currentMusteringEventwithFacility: variables.domain+ "/alerts/fetchActiveAlertByFacility",
    specificMusteringEventUsers: variables.domain+ "/alerts/fetchUsersByAlertId",
    currentMusteringevent: variables.domain+"/alerts",
    musteringLocation: variables.domain+"/musteringLocations",
    musteringLocationWithFacility: variables.domain+"/musteringLocations/fetchByFacility",
    alertCheckIn: variables.domain+"/alertCheckIn",

    alarms: variables.domain+"/alarms",
    alarmsByFacilityId: variables.domain + "/alarms/fetchAllByFacility",

    musteringHistory: variables.domain +"/alerts/fetchAlertHistoryByFacility",
    musteringHistoryDetail: variables.domain + "/alerts/fetchUsersByAlertId",

    dashboardDetail: variables.domain + "/dashboard/overallCount",

    serviceAllocation: variables.domain + "/featureAllocation",
    serviceAllocationByFacilityId: variables.domain + "/featureAllocation/fetchByUser",
    musteringLocations: variables.domain +"/musteringLocations",
    musteringLocationsByFacility: variables.domain + "/musteringLocations/fetchByFacility",

    userPermissions: variables.domain + "/users/fetchPermissions",

    alertSetUp: variables.domain + "/facility/fetchConfigurations",
    updateAlertSetUp: variables.domain + "/facility/updateConfigurations",

    devices: variables.domain + "/devices/fetchAllByFacility",
    deviceDetails : variables.domain + "/devices/clientStatus",
    devicesDelete : variables.domain +"/devices",
    deviceAdd : variables.domain+"/devices",
    deviceEdit : variables.domain+"/devices",
    deviceDetach : variables.domain+"/devices/detach",
    fetchDeviceDetails : variables.domain+"/devices/publishMessage",
    fetchDeviceLogsList: variables.domain + "/support/deviceLogs",

    downloadDeviceLogs : variables.domain + "/support/deviceLogs/downloadLog",

    eventsByFacility : variables.domain + "/eventsByFacility",
    events : variables.domain + "/events",
    fetchEventUrl : 'https://api2.branch.io/v1/url',

    tonesByFacility: variables.domain + "/tonesByFacility",
    tones: variables.domain + "/tones",

    classes : variables.domain + "/classes/fetchClassesAndEnrollments",

    categories: variables.domain + "/docs/categories",
    files: variables.domain + "/docs/files",
    reorder: variables.domain + "/docs/categories/reorder",
    media: variables.domain + "/media",
    bulkMedia: variables.domain + "/media/bulkFetch",
};

export default path;
