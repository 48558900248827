import dropDown from '../../assets/images/Drop_down_arrow.png'
import '../style/ToneLibraryDropDown.css'
import { useRef, useEffect } from 'react';
function ToneLibraryDropDown(props){
    const ref = useRef();
	useEffect(() => {
		const handleClickOutSide = (e) => {
			if (props.trigger && ref.current && !ref.current.contains(e.target)) {
				props.setTrigger(false)
			}
		};
        const timer = setTimeout(() => {
            document.addEventListener("click", handleClickOutSide);
        }, 0);
    
        return () => {
            clearTimeout(timer); // Clear timeout if the component unmounts
            document.removeEventListener("click", handleClickOutSide);
        };
	}, [props.trigger])

    useEffect(() => {
	}, [props.option])

    return(
        <div className='cont'>
        <div className='dropDownContainer' onClick={() => props.setTrigger(!props.trigger)}>

            <span className='selectedToneTitle'>
					{props.selectItem ?
								<span title={props.selectItem}  data-testid = "dropDown-selected-value">
									{props.selectItem}
								</span>
						:
						<span>Select</span>
					}
			</span>
            <span className="drop-down-arrow-tones">
					<img src={dropDown} alt='dropDownArrow' className={!props.trigger ? "drop-down-arrow" : "drop-down-arrow-rotation"} />
			</span>

        </div>
         {props.trigger ?
            <div className="dropdown-content-tones" ref={ref} >
               {props.option ? [...props.option].sort((a, b) => {
                const isANumber = !isNaN(a.name);
                const isBNumber = !isNaN(b.name);

                if (isANumber && !isBNumber) return 1;  
                if (!isANumber && isBNumber) return -1; 
                return a.name.localeCompare(b.name);
            }).map((d,index) =>{
                return(
                    <div className='tone-items' data-testid = "tones" key={index} onClick={(e) => {
                        props.setTrigger(false)
                        props.selectedItem(d)
                    }}>
                     <span >{d.name}</span>

                     </div>
                )
                }) : <div>options is null</div>}
           </div>
            : null
        }
        </div>
    )
}
export default ToneLibraryDropDown
