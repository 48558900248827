import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import Table from '../../common/component/Table'
import AddFacility from "./AddFacility";
import { setAccounts, setFacilities } from '../../redux/action/accountAction';
import { setSnackData } from '../../redux/action/userAction';
import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL";
import Confirmation from "../../common/component/Confirmation"
import '../Style/Facility.css';

function Facility() {
	const dispatch = useDispatch();
	const facilities = useSelector(state => state.accountReducer.facilities)
	
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "/home/system/account", display: "System", type: "react" },
		{ link: '', display:<img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "", display: "Facility", type: "react" },
	];
	const [search, setSearch] = useState('');
	const [modalProps, setModalProps] = useState({});
	const [showFacilityPopup, setShowFacilityPopup] = useState(false);
	const [confirmation, setConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] = useState(false);

	useEffect(() => {
		fetchAccounts()
	}, []);

	const fetchAccounts = async () => {
		let response = await API.getAPI(URL.accounts)
		
		let accountList = response.result?.result || []
		fetchFacility();
		dispatch(setAccounts(accountList))
	}
	const fetchFacility = async () => {
		let response = await API.getAPI(URL.facility)
		let facilityList = response.result.result || []
		dispatch(setFacilities(facilityList))
	}
	const editDetails = (data) => {
		setModalProps(data)
		setShowFacilityPopup(true)
	}
	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationData(data)
	}
	const deleteFacility = async () => {
		let data = {
			"id": confirmationData.id
		}
		let response = await API.deleteAPI(URL.facility, data)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success"
				}
				setConfirmation(false)
				fetchFacility();
				dispatch(setSnackData(snackData))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const filter = (values) => {
		let updatedValues = []
		if (search && search.length > 0) {
			values.map((value) => {
				if (
					value.siteName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
				) {
					updatedValues.push(value)
				}
			})
		} else {
			updatedValues = values
		}
		return updatedValues
	}
	return (
		<div className="facility-page-container" data-testid="facility-page-container" id="wrapper">
			<BreadCrumb crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
			<div className='list-page-title-div'>
				<div className="list-page-title">Facility Management</div>
			</div>
			<div className='facility-action-container'>
				<button className="common-button" onClick={() => setShowFacilityPopup(true)} data-testid="add-facility-button">Add Facility</button>
				<div className='account-filter-ctr'>
					<input type="text" data-testid="facility-search-input" className='facility-search-input' placeholder='Search' value={search}  onChange={(e)=>{setSearch(e.target.value)}}></input>
					<div style={{ display: "flex" }} className="reclear">
						<div data-testid="users-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch('')}} >Reset</div>
					</div>
				</div>
			</div>
			<Table
				headers={[
					{ id: 'siteName', label: 'Facility Name', width: 100, responsive: true, sortable: true,dataTestId:"siteName" },
					{ id: "address", label: "Address", width: 100, sortable: true ,dataTestId:"address" },
					{ id: 'userLicenseLimit', label: 'License Count', width: 80, sortable: true ,dataTestId:"userLicenseLimit" },
					{ id: 'createdOn', label: 'Created On', width: 80, sortable: true ,dataTestId:"createdOn" },
					{ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 70, responsive: true }
				]}
				tableTitle={"Facility Details"}
				data={filter(facilities)}
				editAction={(data) => { editDetails(data) }}
				deleteAction={(data) => showDeleteAction(data)}
				defaultOrderBy={"siteName"}
				defaultSortOrder={"asc"}
			/>
			{showFacilityPopup ?
				<AddFacility
					data={modalProps}
					onCancel={(e) => {
						setShowFacilityPopup(false)
						setModalProps({})
						fetchFacility()
					}}
				/>
			: null}
			{confirmation ?
				<Confirmation deleteMessage={"Are you sure you want to delete facility "+confirmationData.siteName+"?"} successFunc={() => deleteFacility()} cancelFunc={() => setConfirmation(false)} />
			: null}
		</div>
	)
}
export default Facility;
