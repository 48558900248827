import React from "react";
import { Route, Routes, Navigate } from "react-router";
import SignInPage from "./Component/SignInPage";
import SignInPageAfterResetPassword from "./Component/SignInPageAfterResetPassword";
import ForgotPasswordPage from "./Component/ForgotPasswordPage";
import ResetPasswordPage from "./Component/ResetPasswordPage";
import Header from "../common/component/Header";
import Footer from "../common/component/Footer";
import PageNotFound from "../common/component/PageNotFound";
import "./Style/style.css";
function LandingLayout() {
    return (
        <React.Fragment>
            <div className="container">
                <div className="content-container">
                    <Header landing={true} />
                    <Routes>
                        <Route path="/" element={<Navigate replace to="/user/login" />} />
                        <Route path="/login" element={<SignInPage />} />
                        <Route path="/resetPassword" element={<SignInPageAfterResetPassword />} />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPasswordPage />}
                        />
                        <Route
                            path="/reset-password"
                            element={<ResetPasswordPage />}
                        />
                         <Route path="/:PageName" element={<PageNotFound />} />
                    </Routes>
                </div>
                <Footer />
            </div>

        </React.Fragment>
    );
}

export default LandingLayout;
