import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';

import * as API from '../common/api/index.js';
import URL from "../common/api/constantURL";
import { validateSidebar } from "../common/functions/permissionFunctions"
import { setLoading, login, setSnackData, setUserPermissions, setUserProfileDetail } from "../redux/action/userAction";
import { setFacilities } from "../redux/action/accountAction";

import Snack from "../common/component/Snack";
import { SidebarData } from '../common/component/SidebarData';

import Header from "../common/component/Header";
import Footer from "../common/component/Footer";
import Nav from "./Component/Nav";
import Loader from "../common/component/Loader";
import PageNotFound from "../common/component/PageNotFound";
import EULApdfView from "../common/component/EULApdfView.js";
function HomeLayout(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [navsAvailable, setNavsAvailable] = useState([]);
	const isLoading = useSelector(state => state.userReducer.loading)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const userProfileDetail = useSelector(state=>state.userReducer.userProfileDetail)
	useEffect(() => {   
		processLogin();
	}, []);
	function signOut() {
		if(localStorage.getItem('rememberMe') === 'true'){
			localStorage.removeItem("loginUserId");
			localStorage.removeItem("firstName")
			localStorage.removeItem("lastName")
			localStorage.removeItem("accessToken")
			localStorage.removeItem("refreshToken")
			localStorage.removeItem("profilePicture")
			localStorage.removeItem("functionalRoleId")
			localStorage.removeItem("siteLogo")
			localStorage.removeItem("siteName")
			localStorage.removeItem("facilityId")
		}
		else {
			localStorage.clear()
		}
		navigate("/user/login");
	}
	function processLogin() {
		if(
			localStorage.getItem("emailId") !== null && 
			localStorage.getItem("loginUserId") !== null && 
			localStorage.getItem("firstName") !== null && 
			localStorage.getItem("lastName") !== null && 
			localStorage.getItem("accessToken") !== null && 
			localStorage.getItem("refreshToken") !== null && 
			localStorage.getItem("profilePicture") !== null && 
			localStorage.getItem("functionalRoleId") !== null && 
			(localStorage.getItem("functionalRoleId") <= 2 || (localStorage.getItem("functionalRoleId") > 2 && localStorage.getItem("siteLogo") !== null)) &&
			(localStorage.getItem("functionalRoleId") <= 2 || (localStorage.getItem("functionalRoleId") > 2 && localStorage.getItem("siteName") !== null)) &&
			(localStorage.getItem("functionalRoleId") <= 2 || (localStorage.getItem("functionalRoleId") > 2 && localStorage.getItem("facilityId") !== null))
		){
			getPermissions();
			if (localStorage.getItem("functionalRoleId") > 2 && localStorage.getItem("functionalRoleId") < 5) {
				getFacilities();	
			}
			let adminDetail = [];
			adminDetail["emailId"] = localStorage.getItem("emailId");
			adminDetail["loginUserId"] = localStorage.getItem("loginUserId");
			adminDetail["firstName"] = localStorage.getItem("firstName");
			adminDetail["lastName"] = localStorage.getItem("lastName");
			adminDetail["profilePicture"] = localStorage.getItem("profilePicture");
			setProfileImage();
			dispatch(login(adminDetail))
		}else{
			signOut();
		}
	}

	const setProfileImage = async () => {
		let url = `${URL.media}?id=${localStorage.getItem("profileMediaId")}&facilityId=${localStorage.getItem("facilityId")}` ;
		let response =  await API.getAPI(url); 
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				localStorage.setItem("profilePicture", response.result.result.file)
				localStorage.setItem("profileMediaId", response.result.result.id)
				dispatch(setUserProfileDetail({...userProfileDetail,profilePicture:response.result.result.file}))		
			}
		}
	}

	const getFacilities = async () => {
		let response = await API.getAPI(URL.getFacilitybyAccount + "?userId=" + localStorage.getItem("loginUserId"))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let facilityData = response.result.result
				if (facilityData && facilityData.length > 0) {
					dispatch(setFacilities(facilityData))					
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: "No facility Available. Contact Admin",
						snackVariant: "error"
					} 
					dispatch(setSnackData(snackData))
					setTimeout(function () {
						signOut();
					}, 2000); 
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const getPermissions = async () =>{
		dispatch(setLoading(true))
		let url = URL.userPermissions + "?userId=" + localStorage.getItem("loginUserId") +"&type=desktop";
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userPermissions = response.result.result
				let permissions = [];
				if (userPermissions && userPermissions.length > 0) {
					for (let index = 0; index < userPermissions.length; index++) {
						const element = userPermissions[index];
						permissions.push(element.refName)
					}
				}
				dispatch(setUserPermissions(permissions))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	

	useEffect(() => {
		let navsAvailable = []
		let navPaths = [];
		for (let index = 0; index < SidebarData.length; index++) {
			let val = SidebarData[index];
			if (val.subNav) {
				for (let innerIndex = 0; innerIndex < val.subNav.length; innerIndex++) {
					let submenu = val.subNav[innerIndex]
					if(submenu.subNav){	
						for (let innerIndex = 0; innerIndex < submenu.subNav.length; innerIndex++) {
							let innerSubMenu = submenu.subNav[innerIndex]
							if(validateSidebar(userPermissions, innerSubMenu.refName)){
								navPaths.push(innerSubMenu.path)
								navsAvailable.push(<Route key={innerIndex} path={innerSubMenu.navpath} element={innerSubMenu.component} />)
							}
						}
					}
					else if (validateSidebar(userPermissions, submenu.refName)) {
						navPaths.push(submenu.path)
						navsAvailable.push(<Route key={innerIndex} path={submenu.navpath} element={submenu.component} />)
					}
				}
			} else if (validateSidebar(userPermissions, val.refName)) {
				navPaths.push(val.path)
				navsAvailable.push(<Route key={index} path={val.navpath} element={val.component} />)
			}
		}
		setNavsAvailable(navsAvailable)
		if (window.location.pathname === "/home/eulaview") {
			navigate("/home/eulaview")
		}
		else if (navPaths.length > 0 && !navPaths.includes(window.location.pathname)) {
			navigate(navPaths[0])	
		}
	}, [userPermissions]);

	
	return (
		<React.Fragment>
			<div className="container" data-testid="homelayout">
				<div className="content-container">
					<div className="inner-content-container">
						<Header />
						<Snack />
						{isLoading && <Loader/>}
						<div className="nav-container">
							<Nav />
						</div>
						<div className="right-container">
						{localStorage.getItem("functionalRoleId") && (localStorage.getItem("functionalRoleId") < 5 || userPermissions.length > 0) ?
								<Routes>
									{navsAvailable.map((data, index)=>{
										return data
									})}
									{/* {SidebarData.map((val) => {
										return val.subNav ?
											val.subNav.map((submenu) => {
												if (validateSidebar(userPermissions, submenu.refName)) {
													return <Route key={submenu.id} path={submenu.navpath} element={submenu.component} />
												}
											})
										:
											validateSidebar(userPermissions, val.refName) && <Route key={val.id} path={val.navpath} element={val.component} />
									})} */}
									<Route path="*" element={<PageNotFound />} />
									<Route  path="/eulaview" element={<EULApdfView />}></Route>
								</Routes>
							:null}
						</div> 
					</div>
				</div>
				<Footer />
			</div>
		</React.Fragment>
	);
}

export default HomeLayout;
