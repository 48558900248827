import React, {useState } from "react";
import { Link, useNavigate } from "react-router";
import InputField from "../../common/component/InputField";
import validator from "validator";
import "../Style/forgotpassword.css"
import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL"
function ForgotPasswordPage() {
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
    const [EmailError, setEmailError] = useState(false);
    const [ErrorMsg, setErrorMsg] = useState("")
    const [validEmail, setValidEmail] = useState(false);
    // localStorage.clear()
    const navigate = useNavigate();
    const forgotPasswordHandler = async(e) => {
        e.preventDefault();
        if(forgotPasswordEmail.length <=0){
            setErrorMsg("Please enter a Email");
            return setEmailError(true);
        }
        if (validator.isEmail(forgotPasswordEmail) === false) {
            setErrorMsg("Enter a valid email")
            return setEmailError(true);
        }
        let data =  {"userName":forgotPasswordEmail}
        let response = await API.postAPI(URL.forgotPassword,data)
        if(response.fetchStatus === "success"){
           let result = response.result
           if(result.status === "success"){
                setValidEmail(true);
                setTimeout(function() {
                    navigate("/user/login")
                }, 2000);              
           }else{
                setErrorMsg(result.message)
                return setEmailError(true)
           }
        }else if(response.fetchStatus === "failure"){
            setErrorMsg("Server down. Failed to fetch.")
            return setEmailError(true)    
        }      
    };
    const forgotPasswordOnChangeHandler = (event) => {
        event.preventDefault();
        setEmailError(false);
        setValidEmail(false);
        setForgotPasswordEmail(event.target.value);
    };
    return (
        <React.Fragment>
            <div className="forgot-password-section" onSubmit={forgotPasswordHandler}>
                <form className="forgot-password-container">
                    <div className="fogot-password-container-heading">
                        <h1 data-testid="forgot-password-title">
                            Reset Password
                        </h1>
                    </div>
                    <div className="forgot-password-input">
                        <InputField
                            label="Email"
                            type="Email"
                            name="email"
                            autoFocus={true}
                            dataTestid="email-field"
                            onChange={(event) => {
                                forgotPasswordOnChangeHandler(event);
                            }}
                        />
                    </div>

                    <p
                        className="error-message"
                        data-testid="emailErrorMessage"
                    >
                        {EmailError && ErrorMsg}
                    </p>

                    <p data-testid="email-response-message">
                        {validEmail &&
                            "A link will be sent to the email address you provided to reset your password."}
                    </p>

                    <button
                        className="signIn-button"
                        onClick={forgotPasswordHandler}
                        data-testid="forgot-send-btn"
                    >Send</button>
                    <Link to="/user/login" data-testid="forgot-back-to-login">Back To Login</Link>
                </form>
            </div>
        </React.Fragment>
    );
}

export default ForgotPasswordPage;

