import React, { useEffect, useState } from "react";
import "../style/table.css";
import ASort from '../../assets/svg/sort-amount-up-alt-solid.svg';
import DSort from '../../assets/svg/sort-amount-down-alt-solid.svg';
import CheckedCheckbox from '../../assets/svg/checkbox_on.svg';
import UncheckedCheckbox from '../../assets/svg/checkbox_off.svg';
import notesIcon from '../../assets/svg/notesIcon.svg';
import BlankEditUser from '../../assets/images/BlankEditUser.png';
import * as functions from "../functions/tableFunctions";
import {Link} from 'react-router';
import {FaRegEdit} from 'react-icons/fa'
import  FileArrowdown from "../../assets/svg/file-arrow-down-regular.svg";
import {RiDeleteBinLine} from 'react-icons/ri';
import { TiTick } from "react-icons/ti"
import CopySolid from '../../assets/images/copy-solid.svg';
import DownloadIcon from "../../assets/svg/Download-Icon.svg";
import ArrowIcon from "../../assets/images/Drop_down_arrow.png";
import * as API from '../api/index.js';
import URL from "../api/constantURL";

function Table({ headers, dataTestId, defaultOrderBy, defaultSortOrder, data, editAction, deleteAction, tableTitle, isTitleVisible, disableDeleteForRow, paginationData, perPageData, disablePagination, includeMedia }) {
	const [orderBy, setOrderby] = useState(defaultOrderBy);
	const [sortOrder, setsortOrder] = useState(defaultSortOrder || "asc");
	const [page, setPage] = useState(paginationData > 0 ? paginationData :0);
	const [perPage, setPerPage] = useState(perPageData ?  perPageData : 10);
	const [tableData, setTableDate] = useState([]);
	const [isNotes, setIsNotes] = useState(false);
	const [scrollTop, setScrollTop] = useState(false)

	const handleSort = (column)=> {
		setOrderby(column.id)
		setsortOrder(sortOrder === "desc" ?"asc": "desc")
	}

	useEffect(() => {
		if(tableData.length !== data.length){
			setTableDate(data)
			setPage(0)
		}
		if (disablePagination) {
			setPerPage(data.length)
		}
	}, [data, page,tableData.length])
	useEffect(() => {
		setPage(paginationData > 0 ? paginationData :0)
	}, [perPage])

	const scrollToTop = () => {
		try {
			if (document.querySelector('#wrapper')) {
				document.querySelector('#wrapper').scrollTo({
					top: 0,
					behavior: "smooth"
				});
			} else {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				})
			}
		} catch (e) {
			console.error(e)
		}  
	  };

	useEffect(() => {
			scrollToTop();
			setScrollTop(false)
	}, [scrollTop])
	
	function handlePage(type) {
		if (type === "decrease") {
			setPage(page - 1)
		}else {
			setPage(page + 1)
		}
	}
	let totalPage = Math.ceil(data.length / perPage);
	document.querySelectorAll('.tool-tip').forEach(tooltip => {
		tooltip.addEventListener('mouseenter', () => {
			const tooltipText = tooltip.querySelector('.tool-tip-text');
			const parentRect = tooltip.getBoundingClientRect();
			if (parentRect.top >= 425) {
				tooltipText.classList.add('top');
				tooltipText.style.top = 'auto';
				tooltipText.style.bottom = '105%';	
			} else {
				tooltipText.classList.remove('top');
				tooltipText.classList.add('bottom');
				tooltipText.style.bottom = 'auto';
				tooltipText.style.top = '105%';			
			}
		});
	});
	useEffect(()=>{
		headers.map((column) => {
			if (column.id === "notes") {
				setIsNotes(true)
			}
		})
	},[])

	const [idToURlMap, setIdToURlMap] = useState({})

	useEffect(() => {
		if (!includeMedia) return;

		const fetchMedia = async () => {
			const tableData = functions
			.stableSort(data, functions.getSorting(sortOrder, orderBy))
			.slice(page * perPage, parseInt(page * perPage) + parseInt(perPage));
		
			const mediaIds = tableData.map((row) => row.profileMediaId).filter((id) => id != null && id !== "null");

			if (mediaIds.length === 0) return;
		
			const request = {
				facilityId: localStorage.getItem("facilityId"),
				ids: mediaIds,
			};
	
			const response = await API.postAPI(URL.bulkMedia, request);
	
			if (!response?.result?.result) return;
	
			const idToUrlMap1 = {};
			response.result.result.forEach((row) => {
			  idToUrlMap1[row.id] = row.file;
			});
			setIdToURlMap(idToUrlMap1);
		};

		const newTimeoutId = setTimeout(() => {
		  fetchMedia();
		}, 200);

		return () => clearTimeout(newTimeoutId);
	  }, [data, page ]);


	return (

		<div data-testid="table-container" id="table-container" className={!isNotes ? "table-container" : "table-container flow" }>
			{!disablePagination ? <div data-testid="table-options-container" id="table-options-container" className="table-options-container">
				<div className="table-title-container" data-testid="table-title-container"  id="table-title-container">
						{tableTitle ? tableTitle + " - " + data.length: isTitleVisible === false ? "":`Total Entries - ${data.length}`}
					</div>
				<div className="table-page-entry-container">
					<div className="table-entry-container">
						Show
						<select className="table-select" value={perPage} onChange={(event) => setPerPage(event.target.value)} id={"show-entry-drop-down"} data-testid={"show-entry-drop-down"}>
							<option value={10}  id="select-option-10">10</option>

							{data.length > 10 ?
								<option value={25} data-testid="select-option" id="select-option-25">25</option>
							:null}
							{data.length > 25 ?
								<option value={50} data-testid="select-option" id="select-option-50">50</option>
							: null}
							{data.length > 50 ?
								<option value={100} data-testid="select-option" id="select-option-100">100</option>
							: null}
						</select>
						Entries
					</div>
					<div className="table-paginate-container">
						<span>Page</span>
						<div className="table-paginate-inner-container">
							<img src={ArrowIcon} alt="previous" className="table-paginate-next-prev table-paginate-prev" data-testid="table-paginate-previous" id="table-paginate-previous" onClick={page !== 0 ?() => {handlePage("decrease")}: null}></img>
							<span className="table-paginate-page">{page+1}</span>
							<img src={ArrowIcon} alt="next" className="table-paginate-next-prev table-paginate-next" data-testid="table-paginate-next" id="table-paginate-next" onClick={(page+1) < totalPage ? () => {handlePage("increase")} :null}></img>
						</div>
						<span data-testid="table-page-total-count" style={{ width:"44px"}} id="table-page-total-count">Of {totalPage || 1 }</span>
					</div>
				</div>

			</div> : null}
			<div className={!isNotes ? "table-inner-container" : "table-inner-container flow" }>

				<div data-testid="table-header-container" id="table-header-container" className="table-header">
					{headers.map((column, index) => (
						<div

							className={"table-header-data " + (column.responsive ? "show-mobile" : "hide-mobile") + (column.sortable ? " pointer " : "")}
							data-testid="table-header-data"
							key={index}
							style={{
								width: column.width || 120 ,
								minWidth: column.width || 120,
								justifyContent:column.justifyContent || "flex-start",
								paddingLeft: column.id==="manage" && disablePagination ? "unset" : null
							}}
							onClick={column.sortable? () => handleSort(column):null}
							id={"table-header-title-"+index}
						>
							{column.label}
							{column.sortable ?
								orderBy === column.id ?
									sortOrder === "asc" ?
										<img src={ASort} alt="asc" data-testid="ASort-table" id="ASort-table" className={orderBy === column.id ? " active sort-icon" : ""} />
									:
										<img src={DSort} alt="desc" data-testid="DSort-table" id="DSort-table" className={orderBy === column.id ? " active sort-icon" : ""} />
								:<img src={ASort} alt="asc" className="sort-icon" data-testid="ASort-table"/>
							: null}
						</div>
					))}
				</div>
				<div data-testid="table-data-container" id="table-data-container" className={!disablePagination ? "table-body" : "table-body tone-lib-height"}>
					{data.length > 0 ? functions.stableSort(data, functions.getSorting(sortOrder, orderBy))
						.slice(page * perPage, parseInt(page * perPage) + parseInt(perPage))
						.map((row, index) => {
							return (<div key={index} data-testid="table-data" id="table-data" className="table-data-row">
								{headers.map((column, index) => (
									column.type && column.type === "image" ?
										<div
											className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120
											}}
											data-testid="image-table-data"
											id="image-table-data"
										>
											{idToURlMap[row.profileMediaId] ?

												<><img className="image" src={idToURlMap[row.profileMediaId]}  alt="Profile" data-testid={column.dataTestid} id={column.dataTestid} onError={(e) => { e.target.onerror = null; e.target.src = BlankEditUser }} style={{ width: 30 , height : column.height}} />
												{(column.copy) ? <img id = "qrElement"className="imageHover" alt="Qr-code" onClick={()=> column.clickFunc(row, page , "qr")} src={CopySolid}  data-testid={column.dataTestid} onError={(e) => { e.target.onerror = null; e.target.src = BlankEditUser }} style={{ width: 21 , height : 24,padding:2, marginLeft:5}}/> : ""}</>
												
											:
												<img className="image" src={BlankEditUser} alt="" style={{ width: 30 }} data-testid={column.dataTestid} id={column.dataTestid}/>
											}
										</div>
									: column.type === "qr" ?
									<div
									className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
									key={index}
									style={{
										width: column.width || 120,
										minWidth: column.width || 120
									}}
									data-testid="image-table-data"
									id="image-table-data"
									>
												{row.isOpenRegistration === true ?
													(row[column.id] ?
														<><img className="image" src={row[column.id]} data-testid={column.dataTestId} id={column.dataTestid} alt="qr" style={{ width: 40, height: column.height }} />
															{(column.copy) ? <><img alt="qr" id="qrElement" className="imageHover table-copy-icon" onClick={() => column.clickFunc(row, page, "qr")} src={CopySolid} data-testid="QrCopyIcon" style={{ width: 21, height: 24, padding: 2 }} />
															<img alt="qr" id="qrElement" className="imageHover table-copy-icon" onClick={() => column.clickFunc(row, page, "download")} src={DownloadIcon} data-testid="QrDownloadIcon" style={{ width: 21, height: 24, padding: 2 }} />
															</> : ""}</>
														:
														"-"
													) : "-"

												}
										</div>
									: column.type === "url" ?
									<div
									className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
									key={index}
									style={{
										width: column.width || 120,
										minWidth: column.width || 120
									}}
									data-testid="image-table-data"
									id="image-table-data"
									>
												{row.isOpenRegistration === true ?
													(row[column.id] ?
														<><span data-testid={column.dataTestId || column.dataTestid} id={column.dataTestId || column.dataTestid} style={{width: "70%"}}>{row[column.id] === null || row[column.id] === "" || row[column.id] === undefined ? "-" : row[column.id]}</span>
														{(column.copy) ?<img className="imageHover table-copy-icon" alt="Copy-icon" src={CopySolid} onClick={()=> column.clickFunc(row, page, "url")} data-testid="URLcopyIcon" id={column.dataTestid} onError={(e) => { e.target.onerror = null; e.target.src = BlankEditUser }} style={{ width: 21 , height : 24, padding:2,marginLeft:5}}/> : ""}</>
														:
														"-"
													) : "-"

												}
										</div>
									:column.type === "link"?
										<div
										className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											// title={row[column.id]||""}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,

											}}
										>

											<span>{row[column.id] === null || row[column.id] === "" || row[column.id] === undefined ? "-" : <Link  to={column.path}>{row[column.id]}</Link>}</span>
										</div>
									:column.type === "linkclick"?
										<div
											className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											// title={row[column.id]||""}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,
												justifyContent : column.justifyContent || "flex-start"
											}}
										>
											<span onClick={()=> column.clickFunc(row, page)}>{row[column.id] === null || row[column.id] === "" || row[column.id] === undefined ? "-" : <Link style={{fontSize: column.fontSize || 12, color: column.color,justifyContent:column.justifyContent || "flex-start",}} to={column.path}   data-testid={column.dataTestid} id={column.dataTestid}>{row[column.id]}</Link>}</span>
										</div>
									:column.type === "multiple_data_Link"?
									<div
										className={"table-data multiple-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
										key={index}
										//title={row[column.id]||""}
										style={{
											width: column.width || 120,
											minWidth: column.width || 120,
											display : 'flex',
											justifyContent:column.justifyContent || 'flex-start'
										}}
										title={row[column.id].length > 0 && row[column.id] !== "-" && row[column.id] !== null && row[column.id] !== "" && row[column.id] !== undefined ?  row[column.id].map((data, index)=>{return data}) : "-"}
									>
										<span   data-testid={column.dataTestid}>

											{row[column.id] && row[column.id].length > 0 ?
												row[column.id].map((value, index)=>{
													let seperator = "";
													if((row[column.id].length > 1) && (row[column.id].length !== index+1 )){
														seperator = ","
													}
													if (value) {
														return <span data-testid="multiple_data" onClick={()=> column.clickFunc(row, index)}> <Link style={{fontSize: column.fontSize || 12}} to={column.path}  >{value} {seperator}</Link></span>
													}
													else {
														return null;
													}
												})
											:
												"-"
											}
										</span>
									</div>
									:column.type === "checkbox"?
										<div
											className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											// title={row[column.id]||""}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,
												justifyContent:column.justifyContent || 'flex-start'
											}}
										>
											<span id={column.dataTestid} data-testid={column.dataTestid}><input type="checkbox" checked={row[column.id] || false}/></span>
										</div>
									:column.type === "checkbox_icon"?
									<div
										className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
										key={index}
										// title={row[column.id]||""}
										style={{
											width: column.width || 120,
											minWidth: column.width || 120,
											justifyContent:column.justifyContent || 'center',
											paddingRight: "3%"
										}}
									>
										<span style={{"textAlign":"center"}} data-testid={column.dataTestid} id={column.dataTestid}>
											{row[column.id]?
											<img
											style={{
												width: "80%",
												minWidth: "80%",
											}} alt="checkbox"
											src={CheckedCheckbox}></img>
											:<img
											style={{
												width: "80%",
												minWidth: "80%",
											}} alt="checkbox"
											src={UncheckedCheckbox}></img>}
											</span>
									</div>
									:column.type === "multiple_data"?
										<div
											className={"table-data multiple-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											// title={row[column.id]||""}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,
												display:"flex",
												alignItems: "center",
												justifyContent:column.justifyContent || 'flex-start'
											}}
											title={row[column.id].length > 0 && row[column.id] !== "-" && row[column.id] !== null && row[column.id] !== "" && row[column.id] !== undefined ?  row[column.id].map((data, index)=>{return data}) : "-"}
										>
											<span   data-testid={column.dataTestid}>
												{row[column.id] && row[column.id].length > 0 ?
													row[column.id].map((value, index)=>{
														let seperator = "";
														if((row[column.id].length > 1) && (row[column.id].length !== index+1 )){
															seperator = ","
														}
														if (value) {
															return <span key={index} className="table-multiple-data">{value}{seperator}</span>
														}
													})
												:
													"-"
												}
											</span>
										</div>
									: column.type === "check_icon" ?
										<div
											data-testid={column.dataTestId}
											className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,
												justifyContent: column.justifyContent || 'center'
											}}
										>

											<span   data-testid={column.dataTestid} id={column.dataTestid}>
												{row[column.id] ?
													<TiTick size="25px" data-testid="pendingStatusTrue"/>
												:"-"}
											</span>
										</div>

									:column.type === "alarmButton"?
								        <div
											className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											// title={row.buttonName||""}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,
												justifyContent:column.justifyContent || 'flex-start'
											}}

										>
											<button className="table-btn-field" style={{
												background:row.buttonColor || "black"
											}}>{row.icon ? <img src={row.icon} alt="Icon" className="icon-style" /> : <span className="icon-style" />}<span className="btn-txt">{row.buttonName}</span></button>
										</div>
									: column.type === "action" ?
										<div
											className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											// title={row[column.id] || ""}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,
											}}
											data-testid="manage-action-container"
											id="manage-action-container"
										>
											{editAction && !row.disableEditForRow ?
												<FaRegEdit data-testid="edit-action-icon" id="edit-action-icon" style={{color:'#77838F',width:'20px',height:'25px',cursor:'pointer'}} onClick={()=>{editAction(row, page, perPage)}}/>
											:""}
											{deleteAction && (disableDeleteForRow ? !(row.loggedInUser) && !(row.disableDeleteForRow) : true) ?
												<RiDeleteBinLine data-testid="delete-action-icon" id="delete-action-icon" style={{ color: '#77838F', marginLeft: '3px', width: '20px', height: '25px', cursor: 'pointer' }} onClick={() => { deleteAction(row) }} />
											: ""}
										</div>
										
									: column.type === "multipleText" ?
										<div
											className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,
											}}
											title={row[column.id].length > 0 && row[column.id] !== "-" && row[column.id] !== null && row[column.id] !== "" && row[column.id] !== undefined ?  row[column.id].map((data, index)=>{return data}) : "-"}
										>
											<span  	data-testid={column.dataTestId || column.dataTestid} id={column.dataTestId || column.dataTestid} /*title="col-value"*/>{row[column.id] === null || row[column.id] === "" || row[column.id] === undefined ? "-" : row[column.id].length === index+1 ? row[column.id] : row[column.id]+", "}</span>
										</div>
									: column.type === "downloadAction" ?
									<div
										className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
										key={index}
										style={{
											width: column.width || 120,
											minWidth: column.width || 120,
											justifyContent : column.justifyContent || "center",
										}}
										data-testid="manage-download-action-container"
										id="manage-download-action-container"
									>
										{(row.downloadAction !== null && row.downloadAction !== "" && row.downloadAction === true) ? <span onClick={()=> column.clickFunc(row, page)}><img src={FileArrowdown} alt="download" data-testid="action-download" id="action-download" style={{color:'#77838F',width:'20px',height:'25px',cursor:'pointer'}}/></span> : "-"}
									</div>
									: column.type === "notes" ?
									<div
										className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
										key={index}
										style={{
											width: column.width || 120,
											minWidth: column.width || 120,
											justifyContent : column.justifyContent || "center",
										}}
										data-testid="manage-notes-action-container"
										id="manage-notes-action-container"
									>
									{(row[column.id] ? <div className="tool-tip"><img src={notesIcon}  alt="download" data-testid="action-download" id="action-download" style={{color:'#77838F',width:'20px',height:'25px',cursor:'pointer',borderRadius: "0%"}}/><span className="tool-tip-text" style={row[column.id].length > 28 ? { width: '210px'} : {minWidth:'30px',width:"auto",whiteSpace: "nowrap"}}>{row[column.id]}</span></div> : "-")}
									</div>
									:
										<div
											className={"table-data " + (column.responsive ? "show-mobile" : "hide-mobile")}
											key={index}
											title={row[column.id]||""}
											style={{
												width: column.width || 120,
												minWidth: column.width || 120,
												fontWeight : column.fontWeight || "unset",
												justifyContent:column.justifyContent || "flex-start"
											}}
										>
											<span data-testid={column.dataTestId || column.dataTestid} id={column.dataTestId || column.dataTestid} /*title="col-value"*/>{row[column.id] === null || row[column.id] === "" || row[column.id] === undefined ? "-" : row[column.id]}</span>
											{(column.copy) ?<img className="imageHover" alt="Icon" src={CopySolid} onClick={()=> column.clickFunc(row, page, "url")} data-testid={column.dataTestid} id={column.dataTestid} onError={(e) => { e.target.onerror = null; e.target.src = BlankEditUser }} style={{ width: 21 , height : 24, padding:2,marginLeft:5}}/> : ""}
										</div>
								))}
							</div>)
						})
						:
						<div className="no-data" data-testid="no-data-testid" id="no-data-testid"> No data Available </div>
					}
				</div>
			</div>
			{!disablePagination ? <div data-testid="table-options-container" id="table-options-container" className="table-options-container bottom">
				<div className="table-page-entry-container">
					<div className="table-entry-container">
						Show
						<select className="table-select" value={perPage} onChange={(event) => {setPerPage(event.target.value);setScrollTop(true)}} id={"show-entry-drop-down"} data-testid={"show-entry-drop-down"}>
							<option value={10}  id="select-option-10">10</option>

							{data.length > 10 ?
								<option value={25} data-testid="select-option" id="select-option-25">25</option>
							:null}
							{data.length > 25 ?
								<option value={50} data-testid="select-option" id="select-option-50">50</option>
							: null}
							{data.length > 50 ?
								<option value={100} data-testid="select-option" id="select-option-100">100</option>
							: null}
						</select>
						Entries
					</div>
					<div className="table-paginate-container">
						<span>Page</span>
						<div className="table-paginate-inner-container">
							<img src={ArrowIcon} alt="previous" className="table-paginate-next-prev table-paginate-prev" data-testid="table-paginate-previous" id="table-paginate-previous" onClick={()=>(page !== 0 ?(handlePage("decrease"), setScrollTop(true)) : null)}></img>
							<span className="table-paginate-page">{page+1}</span>
							<img src={ArrowIcon} alt="next" className="table-paginate-next-prev table-paginate-next" data-testid="table-paginate-next" id="table-paginate-next"   onClick={(page+1) < totalPage ? () => {handlePage("increase"); setScrollTop(true)} :null}></img>
						</div>
						<span data-testid="table-page-total-count" style={{ width:"44px"}} id="table-page-total-count">Of {totalPage || 1 }</span>
					</div>
				</div>

			</div> : null} 
		</div>
	);
}

export default Table;
